@import url('https://fonts.googleapis.com/css2?family=Alata&family=Josefin+Sans:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;1,300;1,400;1,600&family=Poppins:ital,wght@0,100;0,400;0,600;0,800;1,100;1,400;1,600;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#logo{
  font-family: "Akira Expanded";
}