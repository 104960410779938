*{
    scroll-behavior: smooth;
    text-transform: uppercase;
    font-family: "Maven Pro", "Montserrat", sans-serif;
}
.navbar-logo{
    display: flex;
    justify-content: start;
    width: fit-content;
    height: fit-content;
}
#logo{
    margin-left: 2rem;
    width: 50%;
    height: 50%;
}
ul{
    margin: 0;
}
.NavBarItems {
    background-color: #191919;
    color:white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 0;
    cursor: pointer;

}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}
.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all ease-out 0.5s;
    font-size: 16px;
}
.nav-links:hover{
    background-color: rgb(37, 37, 37);
    border-radius: 4px;
}
.fa-bars {
    color: white;
}
.menu-icon {
    display: none;
}

@media screen and (max-width: 960px){
    .NavBarItems{
        position: relative;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 200px;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background-color: #191919;
        padding: 0;
        padding-bottom: 2rem;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links:hover{
        background-color: rgb(37, 37, 37);
        border-radius: 0;
    }
    .nav-links{
        margin: 2rem;
    }
    #logo{
        font-size: 10pt;
    }
    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,110%);
        font-size: 1.0rem;
        cursor: pointer;
    }
    ul{
        padding: 0; margin-top: 0;
    }
}