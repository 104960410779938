.works-section *{
    transition: all 0.5s ease;
}
.works-section{
    margin: 0; 
    padding: 5rem 14rem;
    background: linear-gradient(to left, rgb(0, 0, 0), rgb(53, 53, 53));
}
.image-works {
    width: fit-content; height: fit-content;
}
.image-works img{
    max-width:100%; 
    height:auto;
    transition: all 0.3s ease-out;
}
.image-works img:hover{
    border: none;
    border-radius: none;
    transform: scale(1.01);
}
#video-clipe{
    width: 1920px;
    height: auto;
}
.gallery {
    margin: 0; padding:0;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto,1fr);
    gap: 2.5rem;
}
.work-title{
    cursor: pointer;
    text-transform: uppercase;
    margin: 2rem 0rem;
    width: fit-content;
    color: white;
    background-color: transparent;
    display: flex;
    justify-content: start;
}
.work-title h3{
    font-family: "Monserrat", sans-serif; font-weight: 600;
    text-align: left;
    font-size: 48pt;
    padding: 0rem;
}
.work-title h3:hover{
    text-align: end;
    font-size: 42pt;
    background-color: white;
    color: black;
    padding: 3rem;
}
.videos{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 60%;
}
.videos > iframe{
    position: absolute;
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1100px){
    .works-section{
        margin: 0; padding: 2rem 0;
        background: linear-gradient(to left, rgb(0, 0, 0), rgb(53, 53, 53));
    }
    .videos{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        max-width: 1920px;
        height: 100%;
    }
    .work-title{
        cursor: pointer;
        text-transform: uppercase;
        margin: 0;
        width: fit-content;
        color: white;
        background-color: transparent;
        display: flex;
        justify-content: start;
    }
    .work-title h3{
        font-family: "Monserrat", sans-serif; font-weight: 600;
        text-align: center;
        font-size: 24pt;
        padding: 3rem;
    }
    .work-title h3:hover{
        text-align: center;
        font-size: 15pt;
        background-color: transparent;
        color: white;
        border-radius: none;
    }
    .gallery {
        margin: 0; padding:0;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(1, auto);
        gap: 1rem;
    }
    .image-works img{
        max-width:100%; 
        height:auto;
    }
    #video-clipe{
        max-width: auto;
        height: auto;
    }
}