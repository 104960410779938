@import url(https://fonts.googleapis.com/css2?family=Alata&family=Josefin+Sans:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;1,300;1,400;1,600&family=Poppins:ital,wght@0,100;0,400;0,600;0,800;1,100;1,400;1,600;1,800&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#logo{
  font-family: "Akira Expanded";
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Monserrat', sans-serif;
}
.App {
  text-align: center;
}

*{
    scroll-behavior: smooth;
    text-transform: uppercase;
    font-family: "Maven Pro", "Montserrat", sans-serif;
}
.navbar-logo{
    display: flex;
    justify-content: start;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
#logo{
    margin-left: 2rem;
    width: 50%;
    height: 50%;
}
ul{
    margin: 0;
}
.NavBarItems {
    background-color: #191919;
    color:white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 0;
    cursor: pointer;

}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}
.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all ease-out 0.5s;
    font-size: 16px;
}
.nav-links:hover{
    background-color: rgb(37, 37, 37);
    border-radius: 4px;
}
.fa-bars {
    color: white;
}
.menu-icon {
    display: none;
}

@media screen and (max-width: 960px){
    .NavBarItems{
        position: relative;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 200px;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background-color: #191919;
        padding: 0;
        padding-bottom: 2rem;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links:hover{
        background-color: rgb(37, 37, 37);
        border-radius: 0;
    }
    .nav-links{
        margin: 2rem;
    }
    #logo{
        font-size: 10pt;
    }
    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%,110%);
                transform: translate(-100%,110%);
        font-size: 1.0rem;
        cursor: pointer;
    }
    ul{
        padding: 0; margin-top: 0;
    }
}
.about{
    padding: 0 14rem;
}
.about *{
    transition: all ease-out 0.5s;
}
.image-text-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    flex-flow: row;
    grid-gap: 3rem;
    gap: 3rem;
    justify-content: space-evenly;
    align-items: center;
    padding: 5rem 0; padding-top: 0;
}
.image-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wedding-image{
    width: 100%;
    height: 100%;
    transition: all 1s ease-out;
}
.wedding-image:hover{
    box-shadow: 20px 20px 20px #6b6b6b;
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
}
.about-us{
    font-family: 'Montserrat', sans-serif;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 48pt;
    font-weight: 600;
    text-align: center;
    margin: 5rem 0; padding: 0;
    transition: all 0.5s ease;
    cursor: pointer;
}
.about-us:hover{
    font-size: 40pt;
    color: white;
    background-color: #191919;
    padding: 2rem;
}
.para{
    font-family: 'Montserrat', sans-serif; font-size: 10pt;
    width: 100%;
    height: auto;
    font-weight: 400;
    text-align: left;
    padding: 3rem; 
    color: #fff;
    background-color: #191919;
    border-radius: 2rem;
    box-shadow: 20px 20px 20px #6b6b6b;
}
.para:hover{
    border-radius: 0;
    box-shadow: 0 0 0 none;
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
}

@media screen and (max-width: 1200px){
    .about{
        padding: 1rem;
    }
    .image-text-container{
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }
    .wedding-image{
        width: 100%;
        height: 100%;
        transition: all 1s ease-out;
    }
    .about-us{
        font-family: 'Montserrat', sans-serif; font-weight: 600;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 20pt;
        text-align: center;
        margin: 2rem 1rem; padding: 0;
        text-align: center;
        transition: all 0.5s ease;
        cursor: pointer;
    }
    .about-us:hover{
        padding: 1rem 1rem;
        text-align: center;
    }
    .para{
        text-align: justify;
        font-family: 'Montserrat', sans-serif;
        font-size: 10pt;
        font-weight: 400;
        color: #191919;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    .para:hover{
        border-radius: 0;
        box-shadow: 0 0 0 none;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}
.works-section *{
    transition: all 0.5s ease;
}
.works-section{
    margin: 0; 
    padding: 5rem 14rem;
    background: linear-gradient(to left, rgb(0, 0, 0), rgb(53, 53, 53));
}
.image-works {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; height: -webkit-fit-content; height: -moz-fit-content; height: fit-content;
}
.image-works img{
    max-width:100%; 
    height:auto;
    transition: all 0.3s ease-out;
}
.image-works img:hover{
    border: none;
    border-radius: none;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
#video-clipe{
    width: 1920px;
    height: auto;
}
.gallery {
    margin: 0; padding:0;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto,1fr);
    grid-gap: 2.5rem;
    gap: 2.5rem;
}
.work-title{
    cursor: pointer;
    text-transform: uppercase;
    margin: 2rem 0rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    background-color: transparent;
    display: flex;
    justify-content: start;
}
.work-title h3{
    font-family: "Monserrat", sans-serif; font-weight: 600;
    text-align: left;
    font-size: 48pt;
    padding: 0rem;
}
.work-title h3:hover{
    text-align: end;
    font-size: 42pt;
    background-color: white;
    color: black;
    padding: 3rem;
}
.videos{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 60%;
}
.videos > iframe{
    position: absolute;
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1100px){
    .works-section{
        margin: 0; padding: 2rem 0;
        background: linear-gradient(to left, rgb(0, 0, 0), rgb(53, 53, 53));
    }
    .videos{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        max-width: 1920px;
        height: 100%;
    }
    .work-title{
        cursor: pointer;
        text-transform: uppercase;
        margin: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        color: white;
        background-color: transparent;
        display: flex;
        justify-content: start;
    }
    .work-title h3{
        font-family: "Monserrat", sans-serif; font-weight: 600;
        text-align: center;
        font-size: 24pt;
        padding: 3rem;
    }
    .work-title h3:hover{
        text-align: center;
        font-size: 15pt;
        background-color: transparent;
        color: white;
        border-radius: none;
    }
    .gallery {
        margin: 0; padding:0;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(1, auto);
        grid-gap: 1rem;
        gap: 1rem;
    }
    .image-works img{
        max-width:100%; 
        height:auto;
    }
    #video-clipe{
        max-width: auto;
        height: auto;
    }
}
.wedd-section{
    background: linear-gradient(to left, #fff, #a8a8a8);
}
.wedd-section *{
    box-sizing: border-box;
}
.wedd-image{
    width: 100%;
    height: auto;
    margin: 0; padding: 0;
}
.wedd-image img{
    width: 100%;
    transition: all 0.5s ease-out;
}
.wedd-image img:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
.videos > iframe{
    transition: all 0.5s ease-in;
}
.videos > iframe:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
.wedd-gallery{
    cursor: pointer;
    padding: 0 14rem; margin: 0;
    padding-bottom: 5rem;
    display:grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}
.wedd-title{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center; 
    padding: 3rem 14rem;
}
.wedd-title h4{
    font-family: "Montserrat", sans-serif;
    font-weight: 600; font-size: 48pt;
    padding: 2rem 0;
    color: #191919;
    transition: all 0.5s ease-out;
}
.wedd-title h4:hover{
    background-color: black;
    color: white;
    border-radius: 2px;
    padding: 2rem 2rem;
    font-size: 40pt;
    font-weight: 600;
}
@media screen and (max-width: 1500px){
    .wedd-title{
        padding: 3rem 6rem;
    }
    .wedd-gallery{
        padding: 3rem 6rem;
    }
}
@media screen and (max-width:960px){
    .wedd-title{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        text-align: center;
        margin: 0 auto; padding:2rem;
    }
    .wedd-title h4{
        font-family: "Montserrat", sans-serif;
        font-weight: 600; font-size: 20pt;
        margin: 0; margin-top: 0.5rem;padding: 1rem;
        color: #191919;
        transition: all 0.5s ease-out;
        text-align: center;
    }
    .wedd-title h4:hover{
        background-color: black;
        color: white;
        border-radius: 2px;
        padding: 1rem 1rem;
        text-align: center;
        font-size: 20pt;
    }
    .wedd-gallery{
        cursor: pointer;
        padding: 1rem; margin: 0;
        display:grid;
        grid-gap: 0.1rem;
        gap: 0.1rem;
        grid-template-columns: repeat(1, minmax(100px,100%));
        justify-items: center;
        align-items: center;
    }
    .wedd-image img{
        max-width: 100%;
        height: auto;
        transition: all 0.5s ease-out;
    }
    .wedd-image{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: auto;
        margin: 0; padding: 0;
    }
}
.team-section{
    background: linear-gradient(to right, #404040, #000);
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 14rem;
}
.team-title{
    color: #191919;
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5rem 0 0 0; padding: 2rem 5rem;
    box-shadow: 20px 20px 20px #191919;
    transition: all ease-out 0.5s;
}
.team-title:hover, .team-title h5:hover{
    box-shadow: 0 0 0 #191919;
    background: linear-gradient(to left, #252525, #000);
    color: white;
}
.team-title h5{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 48pt;
}
.team-stuff{
    margin: 3rem 0; padding: 5rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
}
.team-picture{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0; padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
}
.team-picture h5{
    margin: 2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 30pt;
    text-align: center;
}
.team-picture img{
    width: 100%;
    transition: all 0.2s ease-out;
    border-radius: 50%;
}
.team-picture img:hover{
    width: 105%;
}
p{
    text-align: justify;
    font-family: "Maven Pro","Montserrat", sans-serif;
    font-weight: 400;
}
@media screen and (max-width:1500px){
    .team-section{
        padding: 0 6rem;
    }
    .team-stuff{
        padding: 0;
    }
}
@media screen and (max-width:1300px){
    .team-section{
        padding: 0 10rem;
    }
    .team-stuff{
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width: 1100px) {
    .team-section{
        padding: 0 1rem;
        justify-content: center;
        align-items: center;
    }	
    .team-title h5{
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 35pt;
    }
    .team-title{
        text-align: center;
        color: #191919;
        background-color: white;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 2rem 0; padding: 1.5rem;
        box-shadow: 20px 20px 20px #191919;
        transition: all ease-out 0.5s;
    }
    .team-title:hover, .team-title h5:hover{
        box-shadow: 0 0 0 #191919;
        background: linear-gradient(to left, #252525, #000);
        color: white;
    }
    .team-stuff{
        margin: 0; padding: 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
    .team-picture{

        width: -webkit-fit-content;

        width: -moz-fit-content;

        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0; padding: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }
    .team-picture h5{
        margin: 0.5rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 15pt;
    }
    .team-picture img{
        width: 80%;
        transition: all 0.5s ease-out;
        border-radius: 50%;
    }
    .team-picture img:hover{
        width: 80%;
    }
}
