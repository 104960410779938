.about{
    padding: 0 14rem;
}
.about *{
    transition: all ease-out 0.5s;
}
.image-text-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    flex-flow: row;
    gap: 3rem;
    justify-content: space-evenly;
    align-items: center;
    padding: 5rem 0; padding-top: 0;
}
.image-container{
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wedding-image{
    width: 100%;
    height: 100%;
    transition: all 1s ease-out;
}
.wedding-image:hover{
    box-shadow: 20px 20px 20px #6b6b6b;
    transform: rotate(-2deg);
}
.about-us{
    font-family: 'Montserrat', sans-serif;
    width: fit-content;
    font-size: 48pt;
    font-weight: 600;
    text-align: center;
    margin: 5rem 0; padding: 0;
    transition: all 0.5s ease;
    cursor: pointer;
}
.about-us:hover{
    font-size: 40pt;
    color: white;
    background-color: #191919;
    padding: 2rem;
}
.para{
    font-family: 'Montserrat', sans-serif; font-size: 10pt;
    width: 100%;
    height: auto;
    font-weight: 400;
    text-align: left;
    padding: 3rem; 
    color: #fff;
    background-color: #191919;
    border-radius: 2rem;
    box-shadow: 20px 20px 20px #6b6b6b;
}
.para:hover{
    border-radius: 0;
    box-shadow: 0 0 0 none;
    transform: rotate(2deg);
}

@media screen and (max-width: 1200px){
    .about{
        padding: 1rem;
    }
    .image-text-container{
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }
    .wedding-image{
        width: 100%;
        height: 100%;
        transition: all 1s ease-out;
    }
    .about-us{
        font-family: 'Montserrat', sans-serif; font-weight: 600;
        width: fit-content;
        font-size: 20pt;
        text-align: center;
        margin: 2rem 1rem; padding: 0;
        text-align: center;
        transition: all 0.5s ease;
        cursor: pointer;
    }
    .about-us:hover{
        padding: 1rem 1rem;
        text-align: center;
    }
    .para{
        text-align: justify;
        font-family: 'Montserrat', sans-serif;
        font-size: 10pt;
        font-weight: 400;
        color: #191919;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    .para:hover{
        border-radius: 0;
        box-shadow: 0 0 0 none;
        transform: rotate(0);
    }
}